$light-grey: #d6d9db;
$grey: #37404b;
$pink: #e94d71;
$teal: #03bfb2;
$green: #4a8433;
$orange: #f49a09;
$red: #e95434;

.govuk-panel--confirmation{
  background-color: $green;
}

.govuk-button {
  font-weight: 700;
  background-color: $pink;
  border: 3px solid $pink;
  border-radius: 4.5rem;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.16688rem;
  line-height: 1.3;
  margin: 0 0 0.75rem;
  padding: 0.625rem 1.5rem;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color .1s ease-out,border-color .1s ease-out,color .1s ease-out,opacity .1s ease-out;
  -o-transition: background-color .1s ease-out,border-color .1s ease-out,color .1s ease-out,opacity .1s ease-out;
  transition: background-color .1s ease-out,border-color .1s ease-out,color .1s ease-out,opacity .1s ease-out;
  vertical-align: middle;
  box-shadow: none;

  &:hover, &:focus, &:active, &:focus,  {
    background-color: white;
    border-color: $pink;
    color: $pink;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
  }
}

.govuk-button--disabled:hover, .govuk-button[disabled="disabled"]:hover, .govuk-button[disabled]:hover {
  background-color: white;
}

.govuk-button--warning {
  background-color: $red;
  &:hover, &:focus, &:active, &:focus {
    border-color: $red;
    color: $red;
  }
}

.govuk-button--secondary{
  color: $grey;
  background-color: $light-grey;
  border-color: $light-grey;
  &:hover, &:focus, &:active, &:focus {
    border-color: $light-grey;
    color: $grey;
  }
}

.govuk-header, .govuk-footer  {
  background-color: $grey;
  color: white;
  .govuk-footer__link {
    color: white;
  }
}

.govuk-header__container{
  border-color: $orange;
}

.footer-custom-link{
  color: $orange;
}

.govuk-tag {
  background-color: $orange;
}

.loader {
  border-left-color: $orange;
}

.govuk-link:link, .govuk-details__summary {
  color: $green;
}

.govuk-header__logotype {
  background-image: url("/assets/images/lincoln-city-logo-footer.svg");
  display: inline-block;
  min-width: 125px;
  padding-top: 42px;
  background-repeat: no-repeat;
}

.govuk-header__link--service-name{
  margin-top: 6px;
}

.govuk-footer__copyright-logo{
  background-image: url("/assets/images/lincoln-city-logo-footer.svg");
}
